<template>
  <a-layout style="overflow: auto">
    <a-layout class="content" style="padding: 24px; height: 100%">
      <a-modal
        okText="确定"
        cancelText="取消"
        @cancel="onClose"
        @ok="onClose"
        :visible="visible"
        :title="title"
      >
        <wxlogin
          class="wxlink"
          v-if="
            (this.appid != '') & (this.redirect_uri != '') & (this.state != '')
          "
          :appid="appid"
          :href="href"
          :scope="scope"
          :redirect_uri="redirect_uri"
          :state="state"
        ></wxlogin>
        <div class="loading" v-else>
          <a-spin size="large" /><br />
          加载中
        </div>
      </a-modal>
      <div style="width: 70%; height: 100%">
        <a-layout-content
          style="
            background: #fff;
            min-height: 200px;
            height: 25%;
            padding: 24px;
            margin: 0;
          "
        >
          <div class="title">
            <a-icon
              style="fontsize: 20px"
              type="appstore"
              theme="twoTone"
              two-tone-color="#FD8E22"
            />
            <span>项目信息</span>
          </div>
          <div style="height: 106px" class="flow-d-row">
            <div class="flow-d-row" style="flex: 1; height: 100%">
              <div style="width: 106px; height: 106px">
                <img
                  style="width: 100%; height: 100%"
                  v-if="logo == ''"
                  src="./../../../assets/wellogo.png"
                  alt=""
                />
                <img
                  v-else
                  style="width: 100%; height: 100%; border-radius: 5px"
                  :src="logo"
                  alt=""
                />
              </div>
              <div
                style="margin-left: 15px; justify-content: space-between"
                class="flow-d-column"
              >
                <span style="color: #000; font-size: 19px; font-weight: 700">{{
                  welcomelist.project.name
                }}</span>
                <span class="project-small"
                  >状态: &nbsp;<span style="color: orange">{{
                    welcomelist.project.state == 0 ? '招商中' : '未招商'
                  }}</span></span
                >
                <span class="project-small"
                  >开业时间: &nbsp;<span style="color: pink">{{
                    welcomelist.project.openDate
                  }}</span></span
                >
              </div>
            </div>
            <div
              class="flow-d-column"
              style="
                flex: 1;
                border-left: 2px solid #e9e9e9;
                border-right: 2px solid #e9e9e9;
                padding: 0 20px;
                justify-content: space-between;
              "
            >
              <div style="color: #999; font-size: 15px">
                <span class="yuqi-d-name">{{ welcomelist.user.name }}</span
                >&nbsp;/&nbsp;&nbsp;<span>{{ welcomelist.user.roles }}</span>
              </div>
              <div style="color: #999; font-size: 16px">
                {{ welcomelist.user.architectures }}
              </div>
              <div class="flow-d-row">
                <div style="flex: 1; align-items: center" class="flow-d-row">
                  <span class="project-icon"
                    ><a-icon
                      twoToneColor="#5962D9"
                      style="font-size: 18px"
                      type="phone"
                      theme="twoTone" /></span
                  >&nbsp;&nbsp;<span style="color: #555; line-height: 2">{{
                    welcomelist.user.mobile
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="flow-d-column"
              style="
                flex: 1;
                padding: 0 30px;
                justify-content: space-between;
                font-size: 16px;
                color: #333;
              "
            >
              <div
                style="justify-content: space-between; font-size: 14px"
                class="flow-d-row"
              >
                <span>{{ welcomelist.date.date | time }}</span
                ><span>{{ welcomelist.date.weekday }}</span
                ><span>农历 {{ welcomelist.date.lunar }}</span>
              </div>
              <div>
                上次登录Ip:
                <span style="font-size: 14px"
                  >{{ welcomelist.history.ip }} ({{
                    welcomelist.history.header
                  }})</span
                >
              </div>
              <div style="font-size: 15px" class="flow-d-row">
                <a-icon
                  style="font-size: 16px; margin: 3px"
                  type="flag"
                  theme="twoTone"
                />
                <a @click="wxLink">小程序绑定登录</a>
                <a style="margin-left: 30px">修改密码</a>
              </div>
            </div>
          </div>
        </a-layout-content>
        <a-layout-content
          style="
            background: #fff;
            min-height: 230px;
            height: 29%;
            padding: 24px;
            margin: 20px 0 0 0;
          "
        >
          <div style="width: 200px" class="title">
            <a-icon
              style="fontsize: 20px"
              type="shop"
              theme="twoTone"
              two-tone-color="#4C9FFF"
            />
            <span>逾期欠款</span>
          </div>
          <div class="flow-d-row" style="height: 145px">
            <div
              class="flow-d-column"
              style="width: 40%; height: 100%; justify-content: space-around"
            >
              <div class="yuqi-icon">
                <span
                  style="width: 30px; height: 30px; background-color: #feedea"
                  class="project-icon"
                  ><a-icon
                    twoToneColor="#FFA33E"
                    style="font-size: 18px"
                    type="home"
                    theme="twoTone"
                /></span>
                <div style="margin-left: 10px" class="flow-d-column">
                  <div style="color: #333">欠款金额 (元)</div>
                  <div class="yuqi-d-name">
                    {{ unpaylist.unPay }}
                    <!-- {{ (unpaylist.unPay / 10000).toFixed(2) }} 元 -->
                  </div>
                </div>
              </div>
              <div class="yuqi-icon">
                <span
                  style="width: 30px; height: 30px; background-color: #f4e9ff"
                  class="project-icon"
                  ><a-icon
                    twoToneColor="#791393"
                    style="font-size: 18px"
                    type="shop"
                    theme="twoTone"
                /></span>
                <div style="margin-left: 10px" class="flow-d-column">
                  <div style="color: #333">欠款商铺 (个)</div>
                  <div class="yuqi-d-name">{{ unpaylist.unPayNum }}</div>
                </div>
              </div>
            </div>
            <div
              style="
                flex: 1;
                height: 193px;
                margin-top: -50px;
                position: relative;
              "
            >
              <a-tabs default-active-key="1" @change="callback">
                <a-tab-pane key="1" tab="面积出租率"> </a-tab-pane>
                <a-tab-pane key="2" tab="铺位出租率" force-render> </a-tab-pane>
              </a-tabs>
              <div class="project-select">
                <a-select
                  allowClear
                  placeholder="请选择项目"
                  :value="currentProjectId"
                  style="width: 200px"
                  @change="projectChange"
                >
                  <a-select-option
                    :key="item.id"
                    :value="item.id"
                    v-for="item in projectInfos"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="flow-d-row" style="height: 133px">
                <div style="width: 291px; height: 100%" id="main"></div>
                <div
                  class="flow-d-column"
                  style="
                    flex: 1;
                    height: 100px;
                    justify-content: space-around;
                    margin: 17px 0 0 30px;
                  "
                >
                  <div class="yuqi-icon">
                    <span
                      style="background-color: #fd9363"
                      class="yuzu-statu"
                    ></span
                    ><span>未租</span>
                    <span
                      style="margin-left: 10px; font-weight: 500"
                      class="yuqi-d-name"
                      >{{
                        ((parseInt(data[0].value) / Allrent) * 100).toFixed(
                          1
                        ) == NaN
                          ? 0
                          : ((parseInt(data[0].value) / Allrent) * 100).toFixed(
                              1
                            )
                      }}%</span
                    >
                  </div>
                  <div class="yuqi-icon">
                    <span class="yuzu-statu"></span><span>已租</span>
                    <span
                      style="margin-left: 10px; font-weight: 500"
                      class="yuqi-d-name"
                      >{{
                        ((parseInt(data[1].value) / Allrent) * 100).toFixed(
                          1
                        ) == NaN
                          ? 0
                          : ((parseInt(data[1].value) / Allrent) * 100).toFixed(
                              1
                            )
                      }}%</span
                    >
                  </div>
                  <div class="yuqi-icon">
                    <span
                      style="background-color: #72c53f"
                      class="yuzu-statu"
                    ></span
                    ><span>预租</span>
                    <span
                      style="margin-left: 10px; font-weight: 500"
                      class="yuqi-d-name"
                      >{{
                        ((parseInt(data[2].value) / Allrent) * 100).toFixed(
                          1
                        ) == NaN
                          ? 0
                          : ((parseInt(data[2].value) / Allrent) * 100).toFixed(
                              1
                            )
                      }}%</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
        <a-layout-content
          style="
            background: #fff;
            min-height: 220px;
            height: 28%;
            padding: 24px;
            margin: 20px 0 0 0;
          "
        >
          <div style="width: 200px" class="title">
            <a-icon
              style="fontsize: 20px"
              type="shop"
              theme="twoTone"
              two-tone-color="#4C9FFF"
            />
            <span>我的招商</span>
          </div>
          <div style="height: 133px" class="flow-d-row">
            <div
              class="flow-d-column"
              style="
                width: 28%;
                height: 100%;
                color: #333;
                justify-content: space-around;
              "
            >
              <div style="margin-bottom: 10px">洽谈客户</div>
              <div>
                <span>昨日新增</span>
                <span class="yuqi-d-name">
                  &nbsp;&nbsp;{{ businesslist.newClientYesterday }}</span
                >
              </div>
              <div>
                <span>今日新增 </span
                ><span> &nbsp;&nbsp;{{ businesslist.newClientToday }}</span>
              </div>
            </div>
            <div
              class="flow-d-row"
              style="
                border-left: 2px solid #e9e9e9;
                border-right: 2px solid #e9e9e9;
                padding: 0 20px;
                width: 28%;
              "
            >
              <div style="width: 40%; text-align: center">
                <span style="color: #333">客户转化率</span>
                <span class="yuqi-d-name"
                  >{{ (businesslistb * 100).toFixed(2) }}%</span
                >
              </div>
              <div style="width: 60%; text-align: center">
                <a-progress
                  width="100px"
                  :showInfo="false"
                  strokeWidth="14"
                  type="circle"
                  :percent="businesslistb * 100"
                />
              </div>
            </div>
            <div
              id="option"
              style="flex: 1; height: 162px; margin-top: -30px; padding: 0 20px"
            ></div>
          </div>
        </a-layout-content>
        <a-layout-content
          style="background: #fff; padding: 24px; margin: 20px 0 0 0"
        >
          <div class="title1">
            <div>
              <a-icon
                style="fontsize: 20px"
                type="shop"
                theme="twoTone"
                two-tone-color="#4C9FFF"
              />
              <span>资产收益概况</span>
            </div>
            <div>
              <a-month-picker
                :locale="locale"
                :allowClear="false"
                v-model="assetStaticDate"
                @change="monthChange"
                :format="monthFormat"
              />
            </div>
          </div>
          <div style="height: 50px; margin-top: 50px" class="flow-d-row aa">
            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">{{ assetStatic.month }}月应收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentMonthAmout
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>

            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">{{ assetStatic.month }}月实收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentMonthPayed
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>
            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">{{ assetStatic.month }}月未收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentMonthUnpayed
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>
            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">{{ assetStatic.month }}月需收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentBeforeMonthUnpayed
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 50px; margin-top: 20px" class="flow-d-row aa">
            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">全年应收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentYearAmout
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>

            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">全年实收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentYearPayed
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>
            <div class="yuqi-icon title2" style="flex: 1">
              <div class="flow-d-column">
                <span style="color: #333">全年需收</span>
                <div>
                  <span class="yuqi-d-name">{{
                    assetStatic.currentYearUnpayed
                  }}</span>
                  <span style="color: #333">元</span>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </div>
      <div style="width: 30%">
        <a-layout-content
          style="
            background: #fff;
            height: 21%;
            min-height: 173px;
            padding: 24px;
            margin: 0 0 0 24px;
          "
        >
          <div class="title">
            <a-icon
              style="fontsize: 20px"
              type="appstore"
              theme="twoTone"
              two-tone-color="#FD8E22"
            />
            <span>快捷工作台</span>
          </div>
          <div style="margin-top: 15px" class="quick">
            <div @click="onstore">
              <div class="small-image" style="background-color: #ffa33e">
                <a-icon style="fontsize: 30px" type="file-done" />
              </div>
              <span class="kaui-span">管理铺位</span>
            </div>
            <div @click="onsign">
              <div class="small-image" style="background-color: #fe8685">
                <a-icon style="fontsize: 30px" type="audit" />
              </div>
              <span class="kaui-span">签合同</span>
            </div>
            <div @click="onbill">
              <div class="small-image" style="background-color: #7a9aff">
                <a-icon style="fontsize: 30px" type="area-chart" />
              </div>
              <span class="kaui-span">查账单</span>
            </div>
            <div @click="onreport">
              <div class="small-image" style="background-color: #028efd">
                <a-icon style="fontsize: 30px" type="swap" />
              </div>
              <span class="kaui-span">大屏报表</span>
            </div>
          </div>
        </a-layout-content>
        <a-layout-content
          style="
            background: #fff;
            height: 79%;
            max-height: 678px;
            padding: 14px;
            margin: 20px 0 0 24px;
            overflow: auto;
          "
        >
          <div style="justify-content: space-between" class="title flow-d-row">
            <div class="title">
              <a-icon
                style="fontsize: 20px"
                type="shop"
                theme="twoTone"
                two-tone-color="#4C9FFF"
              />
              <span>审批中心</span>
            </div>
            <a @click="ongoto" href="javascript:;">进入审批中心</a>
          </div>
          <div class="contract">
            <div
              class="flow-d-column"
              v-for="(item, index) in userinfolist"
              :key="item.id"
              style="
                padding: 20px 20px;
                height: 140px;
                justify-content: space-between;
                background-color: #f9f9f9;
                margin-bottom: 10px;
              "
            >
              <div class="flow-d-row" style="justify-content: space-between">
                <div class="flow-d-row">
                  <div class="main-logo">
                    <img
                      v-if="item.userinfo.avatar == '0'"
                      src="../../../assets/11.png"
                      alt=""
                    />
                    <img v-else :src="item.userinfo.avatar" alt="" />
                  </div>
                  <div class="flow-d-column">
                    <span class="audit-title"
                      >{{ item.userinfo.name }}({{ item.userinfo.roles }})</span
                    >
                    <span style="font-size: 10px" class="audit-note">{{
                      item.userinfo.architectures
                    }}</span>
                  </div>
                </div>
                <div style="font-size: 13px">
                  <div>{{ auditcenterlist[index].submitTime | time }}</div>
                  <span>{{ auditcenterlist[index].submitTime | time1 }}</span>
                </div>
              </div>
              <div>{{ auditcenterlist[index].description }}</div>
              <div
                class="flow-d-row"
                style="justify-content: space-between; align-items: end"
              >
                <div style="flex: 1" class="audit-note">
                  {{ auditcenterlist[index].submitNote }}
                </div>
                <div class="Audit-dit">
                  {{
                    auditcenterlist[index].linkType == 0
                      ? '铺位调整'
                      : auditcenterlist[index].linkType == 1
                      ? '提交意向'
                      : auditcenterlist[index].linkType == 3
                      ? '账单核销'
                      : auditcenterlist[index].linkType == 21
                      ? '正式合同提交'
                      : auditcenterlist[index].linkType == 22
                      ? '合同重写'
                      : auditcenterlist[index].linkType == 23
                      ? '合同作废'
                      : auditcenterlist[index].linkType == 24
                      ? '变更主体'
                      : '变更条款'
                  }}
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </div>
    </a-layout>
  </a-layout>
</template>
<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
function locationReplace(url) {
  if (history.replaceState) {
    history.replaceState(null, document.title, url)
    // history.go(0);
  } else {
    location.replace(url)
  }
}
import http from '../../../http'
import * as echarts from 'echarts'
import wxlogin from 'vue-wxlogin'
const OSS = require('ali-oss')
let client
// import { nanoid } from "nanoid";
export default {
  components: {
    wxlogin,
  },

  data() {
    return {
      assetStatic: {
        month: '',
        currentMonthAmout: 0,
        currentMonthPayed: 0,
        currentMonthUppayed: 0,
        currentBeforeMonthUppayed: 0,
        currentYearAmout: 0,
        currentYearPayed: 0,
        currentYearUppayed: 0,
      },
      currentProjectId: '',
      currentProject: JSON.parse(localStorage.getItem('store-userlist')),
      monthFormat: 'YYYY-MM',
      moment,
      assetStaticDate: '',
      locale,
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      params: {
        current: 1,
        pageSize: 10,
      },
      logo: [],
      operates: {},
      auditcenterlist: [],
      appid: '',
      visib: 'false',
      scope: '',
      welcomelist: {
        project: {
          name: '',
          state: '',
          openDate: '暂无',
        },
        date: {
          date: '暂无',
          weekday: '暂无',
          lunar: '暂无',
        },
        history: {
          ip: '暂无',
          header: '暂无',
        },
        user: {
          name: '暂无',
          mobile: '暂无',
          email: '暂无',
          architectures: '暂无',
          roles: '暂无',
        },
      },
      unpaylist: {
        unPay: '',
        unPayNum: '',
      },
      rentArea: {
        notRentArea: '',
        rentedArea: '',
        rentingArea: '',
      },
      rentNum: {
        notRentNum: '',
        rentedNum: '',
        rentingNum: '',
      },
      data: [
        { value: '1', name: '未租' },
        { value: '1', name: '已租' },
        { value: '1', name: '预租' },
      ],
      Allrent: '3',
      businesslist: {
        values: [],
        newClientYesterday: '',
        newClientToday: '',
        totalClient: '',
      },
      eye: false,
      eye1: false,
      messagelist: [],
      userinfolist: [],
      businesslistb: '',
      self_redirect: '',
      redirect_uri: '',
      state: '',
      href: 'http://weixin.linkshang.com/cscn/wxCode.css',
      title: '绑定微信',
      visible: false,
    }
  },

  computed: {
    projectInfos() {
      return this.$store.state.projects
    },
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0]
      }
    },
    time1(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[1]
      }
    },
  },
  methods: {
    monthChange(date, dateString) {
      console.log('dateString===', dateString)
      const that = this
      http
        .getAssetUsufructByMonth(
          dateString,
          that.currentProject.projectInfo.projectId
        )
        .then(res => {
          Object.assign(that.assetStatic, { ...res.data.data.monthVO })
        })
      http
        .getAssetUsufructByYear(
          dateString,
          that.currentProject.projectInfo.projectId
        )
        .then(res => {
          console.log('res====', res)
          Object.assign(that.assetStatic, { ...res.data.data.yearVO })
        })
    },
    onmounth() {
      const url = window.location.href
      if (url.indexOf('?') == -1) {
        return
      } else {
        const urls = url.split('?')
        const wxcode = urls[1]
        const ales = urls[0]
        const numb = wxcode.split('=')
        if (numb[1] == 0) {
          this.$message.success('绑定成功')
        } else if (numb[1] == '1') {
          this.$message.error('绑定失败:用户不存在')
        } else if (numb[1] == 2) {
          this.$message.error('绑定失败:用户已绑定')
        } else if (numb[1] == 3) {
          this.$message.error('绑定失败:参数错误')
        }
        setTimeout(() => {
          locationReplace(ales)
        }, 1000)
      }
    },
    callback(e) {
      if (e == 1) {
        this.data[0].value = this.rentArea.notRentArea
        this.data[1].value = this.rentArea.rentedArea
        this.data[2].value = this.rentArea.rentingArea
      } else {
        this.data[0].value = this.rentNum.notRentNum
        this.data[1].value = this.rentNum.rentedNum
        this.data[2].value = this.rentNum.rentingNum
      }
      this.Allrent =
        this.data[0].value + this.data[1].value + this.data[2].value
      this.chart()
    },
    chart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['50%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: '700',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.data,
          },
        ],
      })
    },
    option() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('option'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '0%',
          right: '2%',
          bottom: '0%',
          top: '0%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          data: this.businesslist.names,
        },
        series: [
          {
            name: '2012',
            type: 'bar',
            data: this.businesslist.values,
          },
        ],
      })
    },
    handleOk() {
      console.log()
    },
    onClose() {
      this.visible = false
      this.appid = ''
      this.scope = ''
      this.redirect_uri = ''
      this.state = ''
    },
    wxLink() {
      const that = this
      that.visible = true
      this.getwxInfolink()
    },
    async getwelcomebusiness() {
      try {
        const res = await http.getwelcomebusiness()
        const { success, data } = res.data
        // console.log(data);
        if (success) {
          this.businesslist = data
          if (data.totalClient == 0) {
            this.businesslistb = 0
          } else if (data.values[3] == 0) {
            this.businesslistb = 0
          } else {
            this.businesslistb = data.values[3] / data.totalClient
          }
        }
        this.option()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async projectChange(e) {
      console.log('projectChange===', e)
      this.currentProjectId = e

      if (!e) {
        return
      }

      try {
        const res = await http.getwelcomerentRateByProjectId(
          this.currentProjectId
        )
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.rentRate == null) {
            return
          } else {
            this.rentArea = {
              notRentArea: data.rentRate.notRentArea,
              rentedArea: data.rentRate.rentedArea,
              rentingArea: data.rentRate.rentingArea,
            }
            this.rentNum = {
              notRentNum: data.rentRate.notRentNum,
              rentedNum: data.rentRate.rentedNum,
              rentingNum: data.rentRate.rentingNum,
            }
            this.data[0].value = this.rentArea.notRentArea
            this.data[1].value = this.rentArea.rentedArea
            this.data[2].value = this.rentArea.rentingArea
            this.Allrent =
              this.data[0].value + this.data[1].value + this.data[2].value
          }
        }
        this.chart()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getwelcomerentRate() {
      try {
        const res = await http.getwelcomerentRate()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.rentRate == null) {
            return
          } else {
            this.rentArea = {
              notRentArea: data.rentRate.notRentArea,
              rentedArea: data.rentRate.rentedArea,
              rentingArea: data.rentRate.rentingArea,
            }
            this.rentNum = {
              notRentNum: data.rentRate.notRentNum,
              rentedNum: data.rentRate.rentedNum,
              rentingNum: data.rentRate.rentingNum,
            }
            this.data[0].value = this.rentArea.notRentArea
            this.data[1].value = this.rentArea.rentedArea
            this.data[2].value = this.rentArea.rentingArea
            this.Allrent =
              this.data[0].value + this.data[1].value + this.data[2].value
          }
        }
        this.chart()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getwelcomeunPay() {
      try {
        const res = await http.getwelcomeunPay()
        const { success, data } = res.data
        if (success) {
          this.unpaylist = data
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },

    async getwelcomeoperatest() {
      try {
        const res = await http.getwelcomeoperatest()
        const { success, data } = res.data
        if (success) {
          //   console.log();
          if (data.operate != null) {
            //1
            if (data.operate.inState0 == null) {
              data.operate.inState0 = 0
            }
            if (data.operate.inState1 == null) {
              data.operate.inState1 = 0
            }
            if (data.operate.decorateState0 == null) {
              data.operate.decorateState0 = 0
            }
            if (data.operate.decorateState1 == null) {
              data.operate.decorateState1 = 0
            }
            if (data.operate.decorateState1 == null) {
              data.operate.decorateState1 = 0
            }
            if (data.operate.acceptState0 == null) {
              data.operate.acceptState0 = 0
            }
            if (data.operate.acceptState1 == null) {
              data.operate.acceptState1 = 0
            }
            if (data.operate.openState0 == null) {
              data.operate.openState0 = 0
            }
            if (data.operate.openState1 == null) {
              data.operate.openState1 = 0
            }
            if (data.operate.outState0 == null) {
              data.operate.outState0 = 0
            }
            if (data.operate.outState1 == null) {
              data.operate.outState1 = 0
            }
            this.operates = data.operate
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getwelcomebasic() {
      try {
        const res = await http.getwelcomebasic()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.welcomelist = data
          if (
            JSON.parse(data.project.logo) == [] ||
            JSON.parse(data.project.logo) == ''
          ) {
            this.logo = ''
            return
          }
          this.logo = client.signatureUrl(JSON.parse(data.project.logo)[0].name)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    ongoto() {
      localStorage.setItem('openKeys', ['/audit'])
      localStorage.setItem('selectedKeys', ['/audit/auditCenter'])
      this.$store.state.openKeys = ['/audit']
      this.$store.state.selectedKeys = ['/audit/auditCenter']
      this.$router.push({ name: 'auditCenter' })
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
        this.getwelcomebasic()
      } catch (e) {
        console.error(e)
      }
    },
    async getweixinlink() {
      try {
        const res = await http.getweixinlink()
        const { success } = res.data
        if (success) {
          this.visible = true
          //console.log(data.url);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getwxInfolink() {
      try {
        const res = await http.getwxInfolink()
        const { success, data } = res.data
        if (success) {
          this.appid = data.appId
          this.scope = data.scope
          this.self_redirect = data.self_redirect
          this.redirect_uri = data.redirect_uri
          this.state = data.state
          this.href = data.href
          //   console.log(data);
          if (data.appid != '') {
            this.getweixinlink()
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    eyep() {
      if (this.eye == true) {
        this.eye = false
      } else {
        this.eye = true
      }
    },
    eye2() {
      if (this.eye1 == true) {
        this.eye1 = false
      } else {
        this.eye1 = true
      }
    },
    async onstore() {
      this.$router.push({ name: 'store' })
      localStorage.setItem('openKeys', ['/property'])
      localStorage.setItem('selectedKeys', ['/property/store'])
      this.reload()
      //   this.$router.push({ name: "store", query: { visible: true } });
    },
    onsign() {
      this.$router.push({ name: 'sign' })
      localStorage.setItem('openKeys', ['/business'])
      localStorage.setItem('selectedKeys', ['/business/sign'])
      this.reload()
    },
    onbill() {
      this.$router.push({ name: 'bill' })
      localStorage.setItem('openKeys', ['/operate'])
      localStorage.setItem('selectedKeys', ['/operate/bill'])
      this.reload()
    },
    onreport() {
      const routeUrl = this.$router.resolve({ name: 'report' })
      window.open(routeUrl.href, '_blank')
    },
    async getuserinfo(id) {
      try {
        const res = await http.getuserinfo(id)
        const { data } = res.data
        // if (success) {
        if (
          data.userinfo.avatar == '' ||
          data.userinfo.avatar == null ||
          data.userinfo.avatar == undefined
        ) {
          data.userinfo.avatar = '0'
          this.userinfolist.push(data)
          return
        }
        if (JSON.parse(data.userinfo.avatar).length == 0) {
          data.userinfo.avatar = '0'
          this.userinfolist.push(data)
          return
        }
        JSON.parse(data.userinfo.avatar).forEach(item => {
          if (item.name != null) {
            const url = client.signatureUrl(item.name)
            //   console.log(url);
            data.userinfo.avatar = url
            this.userinfolist.push(data)
          } else {
            data.userinfo.avatar = '0'
            this.userinfolist.push(data)
          }
        })
        // console.log(this.userinfolist);
        // }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getauditCenter() {
      try {
        const res = await http.getauditCenter(this.params)
        const { success, data } = res.data
        if (success) {
          data.audits.records.forEach(item => {
            setTimeout(() => {
              this.getuserinfo(item.submitBy)
            }, 100)
            // console.log(item);
          })
          this.auditcenterlist = data.audits.records
          if (data.audits.records.length < 10) {
            this.getsearchHistory()
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getsearchHistory() {
      try {
        const res = await http.getsearchHistory(this.params)
        const { success, data } = res.data
        if (success) {
          if (data.audits.records.length == 0) {
            return
          }
          data.audits.records.forEach(item => {
            if (this.auditcenterlist.length < 10) {
              setTimeout(() => {
                this.getuserinfo(item.submitBy)
              }, 100)
              this.auditcenterlist.push(item)
            }
          })
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
  },
  //   senderName:'系统'，发送人
  //   receiverName: "李乐"，接受人
  //   content:"您已成功创建与北京新东方迅程网络科技股份有限公司的正式合同",内容
  //   title: "已创建"，标题
  //   process: "合同创建"，状态
  //   时间：sysSendTime: "2021-12-16T11:26:24" ，时间

  mounted() {
    console.log('hahahaha', this.currentProject)
    if (this.currentProject && this.currentProject.projectInfo) {
      this.currentProjectId = this.currentProject.projectInfo.projectId
      console.log('currentProjectId===', this.currentProjectId)
    }
    this.assetStaticDate = moment().format('YYYY-MM')
    this.monthChange(null, this.assetStaticDate)
    this.onmounth()
    this.getsts()
    this.getwelcomeoperatest()
    this.getwelcomerentRate()
    this.getwelcomeunPay()
    this.getwelcomebusiness()
    this.getauditCenter()
  },
}
</script>
<style lang="less" scoped>
/* .ant-layout {
  height: 100%;
  overflow-y: auto;
} */
.project-select {
  position: absolute;
  right: 0;
  top: 10px;
}
span {
  display: inline-block;
  font-family: '微软雅黑';
}
label {
  display: inline-block;
  font-family: '微软雅黑';
}
.ant-layout {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.ant-layout > div {
  display: flex;
  flex-direction: column;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.flow-d-column {
  display: flex;
  flex-direction: column;
}
.project-small {
  color: #666;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 2;
  margin-bottom: 10px;
}
.title1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 2;
  margin-bottom: 10px;
  & span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 7px;
  }
}
.title2 {
  justify-content: center;
}
.title > span {
  font-size: 18px;
  font-weight: bold;
  margin-left: 7px;
}
.quick {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.quick > div {
  display: flex;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.small-image {
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 65px;
  border-radius: 5px;
}
.store {
  display: flex;
  flex-direction: row;
  height: 60%;
  justify-content: space-between;
  align-items: center;
}
.big-image {
  width: 23%;
  height: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}
.big-image > span {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
@media screen and (max-width: 1260px) {
  .content {
    width: 1260px;
  }
}
.big-image > label {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
.big-image > label > span {
  font-size: 15px;
  color: #fff;
}
.loading {
  text-align: center;
}
::v-deep .ant-modal-footer {
  display: none;
}
::v-deep .loginPanel .title {
  display: none;
}
.wxlink {
  text-align: center;
}
.brand {
  display: flex;
  flex-direction: row;
}
.ant-layout-content {
  border-radius: 10px;
}
/* 我发起的 */
.review {
  border-radius: 5px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
}
.review:hover {
  background-color: #f6f6f6;
}
// ::v-deep .anticon svg {
//   color: #fff;
// }
.data {
  padding-left: 10px;
}
.project-icon {
  background-color: #f1f1fc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.yuqi-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.yuqi-d-name {
  color: #000;
  font-size: 18px;
  margin-left: 4px;
  font-weight: 700;
}
.yuzu-statu {
  width: 10px;
  height: 10px;
  background-color: #417bd7;
  border-radius: 50%;
  margin-right: 5px;
}
.project-left,
.project-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .project-left-img img {
    height: 70px;
  }
}
.main-logo img {
  width: 45px;
  height: 45px;
}
.main-logo {
  width: 45px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-right: 5px;
}
.mess-0 {
  color: #fff;
  background-color: #1c90fd;
}
.mess-1 {
  color: #fff;
  background-color: #e6a23c;
}
.mess-2 {
  color: #fff;
  background-color: #67c23a;
}
.audit-title {
  color: #666666;
  font-size: 14px;
}
.audit-note {
  color: #999999;
  font-size: 11px;
}
.kaui-span {
  font-size: 16px;
  margin-top: 5px;
}
.Audit-dit {
  padding: 0 10px;
  height: 20px;
  margin-left: 10px;
  background-color: #def2f6;
  text-align: center;
  line-height: 20px;
  color: #56a2ef;
}
.aa {
  // ::v-deep .anticon svg {
  //   color: #666;
  // }
}
.project-left {
  padding-top: 7px;
}
</style>
